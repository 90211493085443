<template>
  <br>
  <div>
    <n-breadcrumb>
      <n-breadcrumb-item>Processes</n-breadcrumb-item>
      <n-breadcrumb-item>Operations</n-breadcrumb-item>
    </n-breadcrumb>
  </div>

  <div style="text-align: center;">

    <n-grid :x-gap="12" :y-gap="8" :cols="3">
      <n-grid-item>
        <div>
      <h1>Operations</h1>
      <h4>Status and Executions</h4>
      <br>
    </div>
      </n-grid-item>
      <n-grid-item :span="2">

        <div>
          <n-divider />

          <div>
            <router-link to="/menuprocesos/menuoperaciones/consolidatedliquidations" style="text-decoration: none; color: inherit;">
              Consolidated Liquidations
            </router-link>
          </div>
          <n-divider />

          <div>
            <router-link to="/menuprocesos/menuoperaciones/valuedcorporatepl" style="text-decoration: none; color: inherit;">
              Valued Corporate PL
            </router-link>
          </div>
          <n-divider />
        </div>
      </n-grid-item>
    </n-grid><br><br>
    <div>
      <h5>If you do not find any process, please request access to portaloperaciones@hortifrut.com</h5>
    </div>


    <router-view></router-view>
  </div>
</template>

<script>


export default {
  name: 'menuOperaciones',
  components: {

  }
}

</script>

