<template>
  <div style="text-align: center;">
    <h1>Monitors</h1>
    <br>


    <n-grid :x-gap="15" :y-gap="15" :cols="15">
      <n-grid-item :span="3" />
      <n-grid-item :span="3">
        <div>
          <router-link to="/menu-monitores/monitors-business-plannn" style="text-decoration: none; color: inherit;">
            <n-card class="special_hover" hoverable>
              <template #cover>
                <img src="../../assets/bus_plan_md.png">
              </template>
            </n-card>
          </router-link>
        </div>
      </n-grid-item>
      <n-grid-item :span="3">
        <div>
          <router-link to="/menu-monitores/monitors-cont-improv" style="text-decoration: none; color: inherit;">
            <n-card class="special_hover" hoverable>
              <template #cover>
                <img src="../../assets/cont_imp_md.png">
              </template>
            </n-card>
          </router-link>
        </div>
      </n-grid-item>
      <n-grid-item :span="3">
        <div>
          <router-link to="/menu-monitores/monitors-exports" style="text-decoration: none; color: inherit;">
            <n-card class="special_hover" hoverable>
              <template #cover>
                <img src="../../assets/exports_md.png">
              </template>
            </n-card>
          </router-link>
        </div>
      </n-grid-item>
      <n-grid-item :span="3" />
      <n-grid-item :span="3" />
      <n-grid-item :span="3">
        <div>
          <router-link to="/menu-monitores/monitors-food-safety" style="text-decoration: none; color: inherit;">
            <n-card class="special_hover" hoverable>
              <template #cover>
                <img src="../../assets/food_safe_md.png">
              </template>
            </n-card>
          </router-link>
        </div>
      </n-grid-item>
      <n-grid-item :span="3">
        <div>
          <router-link to="/menu-monitores/monitors-imports" style="text-decoration: none; color: inherit;">
            <n-card class="special_hover" hoverable>
              <template #cover>
                <img src="../../assets/imports_md.png">
              </template>
            </n-card>
          </router-link>
        </div>
      </n-grid-item>
      <n-grid-item :span="3">
        <div>
          <router-link to="/menu-monitores/monitors-operations" style="text-decoration: none; color: inherit;">
            <n-card class="special_hover" hoverable>
              <template #cover>
                <img src="../../assets/operations_md.png">
              </template>
            </n-card>
          </router-link>
        </div>
      </n-grid-item>
      <n-grid-item :span="3" />
      <n-grid-item :span="3" />
      <n-grid-item :span="3">
        <div>
          <router-link to="/menu-monitores/monitors-procur-assist" style="text-decoration: none; color: inherit;">
            <n-card class="special_hover" hoverable>
              <template #cover>
                <img src="../../assets/procur_md.png">
              </template>
            </n-card>
          </router-link>
        </div>
      </n-grid-item>
      <n-grid-item :span="3">
        <div>
          <router-link to="/menu-monitores/monitors-quality" style="text-decoration: none; color: inherit;">
            <n-card class="special_hover" hoverable>
              <template #cover>
                <img src="../../assets/quality_md.png">
              </template>
            </n-card>
          </router-link>
        </div>
      </n-grid-item>
      <n-grid-item :span="3">
        <div>
          <router-link to="/menu-monitores/monitors-sop" style="text-decoration: none; color: inherit;">
            <n-card class="special_hover" hoverable>
              <template #cover>
                <img src="../../assets/sop_md.png">
              </template>
            </n-card>
          </router-link>
        </div>
      </n-grid-item>
    </n-grid>
    <br><br>
    <div>
      <h5>If you do not find any process, please request access to portaloperaciones@hortifrut.com</h5>
    </div>


    <router-view></router-view>
  </div>
</template>

<script>
// import { Data1, OperationsField, Area } from '@vicons/carbon'

export default {
  name: 'monitorsMenu',
  components: {
    // Data1, OperationsField, Area
  }
}

</script>

