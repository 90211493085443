<template>
    <br>
    <div style="text-align: center;">
        <div v-if="!isLogged">
            <n-grid :x-gap="12" :y-gap="8" :cols="3">
                <n-grid-item></n-grid-item>
                <n-grid-item>
                    <n-card title="Log In">
                        <n-button strong secondary round type="info" @click="signIn">Log In</n-button>
                    </n-card>
                </n-grid-item>
                <n-grid-item></n-grid-item>
            </n-grid>
        </div>
        <div v-else >
            <n-grid :x-gap="12" :y-gap="8" :cols="3">
                <n-grid-item></n-grid-item>
                <n-grid-item>
                    <h2>Welcome</h2>
                    <h3>Name: {{name}}</h3>
                    <h3>Mail: {{username}}</h3>
                    <n-button strong secondary round type="info" @click="logoff">Log Out</n-button>
                </n-grid-item>
                <n-grid-item></n-grid-item>
            </n-grid>
        </div>
    </div>

</template>

<script>
import aad from "@/services/aad";
// import { NCard, NButton } from "naive-ui";

export default {
    name: 'LoginAzureAD',
    data: () => ({
        username: "",
        isLogged: false,
        name: ""
    }),
    methods: {
        signIn() {
            aad.login().then((account) => {
                this.username = account.username;

            });
        },
        logoff() {
            aad.logoff();
        }
    },
    created() {
        aad.getAccount().then((account) => {
            if (account != null) {
                this.username = account.username;
                this.name = account.name;
                this.isLogged = true;
                // console.log(this.isLogged)
                // console.log('Login-created-if', account)
            }
            else {
                this.isLogged = false;
                // console.log(this.isLogged)
                // console.log('Login-created-else', account)
            }
        });
    }
}    
</script>
