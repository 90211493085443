<template>
  <br>
  <div>
    <n-breadcrumb>
      <n-breadcrumb-item href="#menuprocesos">Processes</n-breadcrumb-item>
      <n-breadcrumb-item href="#menuprocesos/menuIBP">SAP IBP</n-breadcrumb-item>
      <n-breadcrumb-item>Transportation Cost Rate Agg</n-breadcrumb-item>
    </n-breadcrumb>
  </div>
  <div>
    <div style="text-align: center;">
      <div>
        <h1>Transportation Cost Rate Agg</h1>
        <n-divider />
        <n-button type="primary" @click="exec_transp_cost_rate_agg_process"> Run TranspCostRateAgg Process </n-button>
        <n-divider vertical />
        <n-button type="primary" @click="download_transp_cost_rate_agg"> Download Output </n-button>
        <n-divider />
      </div>
      <div>
        <div v-if="show_alert_error_tab1 == true">
          <n-alert title="Error" type="error" closable>
            <pre>{{ message_alert }}</pre>
          </n-alert>
        </div>
        <div v-if="show_alert_success_tab1 == true">
          <n-alert title="Ok!" type="success" closable>
            <pre>{{ message_alert }}</pre>
          </n-alert>
        </div>
      </div>
    </div>
    <div style="height: 300px; border: solid 1px #ddd;">
      <c-grid :data="records">
        <c-grid-input-column field="name" width="35%" min-width="150">
          Id
        </c-grid-input-column>
        <c-grid-input-column field="startDate" width="20%" min-width="150">
          Started On
        </c-grid-input-column>
        <c-grid-input-column field="stopDate" width="20%" min-width="150">
          Completed On
        </c-grid-input-column>
        <c-grid-input-column field="status" width="25%" min-width="150">
          Status
        </c-grid-input-column>
      </c-grid>
    </div>
    <div style="text-align: center;">
      <h5>If you do not find any process, please request access to portaloperaciones@hortifrut.com</h5>
    </div>

  </div>
</template>

<script>
import axios from 'axios'
import { defineComponent, ref } from "vue";
export default defineComponent({
  name: 'TranspCostRateAgg',
  mounted() {
    // this.obtenerEstadoProcesosGlue();
    this.getInfoLastExecution();
  },
  data() {
    return {
      records: [],
      presignedurl_excel: ref(null),
      message_alert: ref(null),
      show_alert_error_tab1: false,
      show_alert_success_tab1: false
    }
  },
  methods: {
    getInfoLastExecution() {
      axios.get("https://14u51m5kzc.execute-api.us-west-2.amazonaws.com/hf-prod-stage/ibp/transp-cost-rate-agg/get-information-data")
        .then((response) => {
          console.log(response.data);
          this.presignedurl_excel = response.data.item.Item.presignedurl.S;
          this.records = response.data.sfn.executions;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    download_transp_cost_rate_agg() {
      window.open(this.presignedurl_excel, '_blank', 'noreferrer');
    },
    exec_transp_cost_rate_agg_process() {
      axios.put("https://14u51m5kzc.execute-api.us-west-2.amazonaws.com/hf-prod-stage/ibp/transp-cost-rate-agg/execute-process").
        then((response) => {
          console.log(response);
          this.show_alert_success_tab1 = true
          this.message_alert = "RequestId: " + response.data.ResponseMetadata.RequestId
        }).catch((error) => {
          console.log(error);
          this.show_alert_error_tab1 = true
          this.message_alert = "Error running Process"
        }).finally(
          this.getInfoLastExecution()
        );
    }
  },
})
</script>

