<template>
  <div>
    <n-grid :x-gap="12" :y-gap="12" :cols="12" layout-shift-disabled>
      <n-gi>
        <div>
          <n-image width="80"
            src="https://www.simfruit.cl/wp-content/uploads/2016/03/images_noticias_2016_Marzo_hortifrut.jpg" />
        </div>
      </n-gi>
      <n-gi :span="11">
        <div style="height: 70px; line-height: 70px;">
          <n-menu v-model:value="activeKey" mode="horizontal" :options="menuOptions" />
        </div>
      </n-gi>
    </n-grid>

  </div>

  <div>
    <!-- <MainMenu></MainMenu>  -->

    <router-view></router-view>
  </div>
</template>

<script>

import { h, ref } from "vue";
import { RouterLink } from "vue-router";

const menuOptions = [
  {
    label: () => h(
      RouterLink,
      {
        to: {
          name: "MainMenu"
        }
      },
      { default: () => "Home" }
    ),
    key: "home",
  },
  {
    label: () => h(
      RouterLink,
      {
        to: {
          name: "MenuMonitores"
        }
      },
      { default: () => "Monitors" }
    ),
    key: "monitors",
    children: [
      {
        label: () => h(
          RouterLink,
          {
            to: {
              name: "MonitorsBusinessPlanning"
            }
          },
          { default: () => "Business Planning" }
        ),
        key: 'busin_plan'
      },
      {
        label: () => h(
          RouterLink,
          {
            to: {
              name: "MonitorsContinuousImprovement"
            }
          },
          { default: () => "Continuous Improvement" }
        ),
        key: 'cont_improv'
      },
      {
        label: () => h(
          RouterLink,
          {
            to: {
              name: "MonitorsExports"
            }
          },
          { default: () => "Exports" }
        ),
        key: 'exports'
      },
      {
        label: () => h(
          RouterLink,
          {
            to: {
              name: "MonitorsFoodSafety"
            }
          },
          { default: () => "Food Safety" }
        ),
        key: 'food_safe'
      },
      {
        label: () => h(
          RouterLink,
          {
            to: {
              name: "MonitorsImports"
            }
          },
          { default: () => "Imports" }
        ),
        key: 'imports'
      },
      {
        label: () => h(
          RouterLink,
          {
            to: {
              name: "MonitorsOperations"
            }
          },
          { default: () => "Operations" }
        ),
        key: 'operations'
      },
      {
        label: () => h(
          RouterLink,
          {
            to: {
              name: "MonitorsProcurementAssistant"
            }
          },
          { default: () => "Procurement Assistant" }
        ),
        key: 'proc_assist'
      },
      {
        label: () => h(
          RouterLink,
          {
            to: {
              name: "MonitorsQuality"
            }
          },
          { default: () => "Quality" }
        ),
        key: 'quality'
      },
      {
        label: () => h(
          RouterLink,
          {
            to: {
              name: "MonitorsSOP"
            }
          },
          { default: () => "S&OP" }
        ),
        key: 'sop'
      },
    ]
  },
  {
    label: () => h(
      RouterLink,
      {
        to: {
          name: "MenuProcesos"
        }
      },
      { default: () => "Processes" }
    ),
    key: "processes",
  },
  {
    label: () => h(
      RouterLink,
      {
        to: {
          name: "MenuDatos"
        }
      },
      { default: () => "Data" }
    ),
    key: "data",
  },
  {
    label: () => h(
      RouterLink,
      {
        to: {
          name: "MenuSoporte"
        }
      },
      { default: () => "Support" }
    ),
    key: "support",
  },
  {
    label: () => h(
      RouterLink,
      {
        to: {
          name: "LoginAzureAD"
        }
      },
      { default: () => "Login" }
    ),
    key: "login",
  },
]

export default ({
  name: 'App',
  components: {
  },
  setup() {
    return {
      activeKey: ref(null),
      menuOptions
    };
  }
})
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  /* margin-top: 30px; */
  margin-left: 30px;
  margin-right: 30px;
}

h1 {
  text-align: center;
}
</style>
