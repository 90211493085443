<template>
  <br>
  <div>
    <n-breadcrumb>
      <n-breadcrumb-item href="#menuprocesos">Processes</n-breadcrumb-item>
      <n-breadcrumb-item>SAP IBP</n-breadcrumb-item>
      <n-breadcrumb-item>Total Universe</n-breadcrumb-item>
    </n-breadcrumb>
  </div>
  <div style="text-align: center;">
    <div>
      <h1>Total Universe</h1>
      <n-divider />
      <n-button type="primary" disabled> Run ETL Job BW-AWS y Process AWS </n-button>
      <n-divider vertical />
      <n-button type="primary" disabled> Run Process AWS </n-button>
      <n-divider />
    </div>
    <div>
      <h5>If you do not find any process, please request access to portaloperaciones@hortifrut.com</h5>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { defineComponent, ref } from "vue";
export default defineComponent({
  name: 'UniversoTotal',
  data() {
    return {
      result: [],
      saludo: ref(null),
      nombre: ""

    }
  },
  methods: {
    api() {

      axios.get('https://14u51m5kzc.execute-api.us-west-2.amazonaws.com/hf-prod-stage/dummy/hola-mundo?param=' + this.nombre)
        .then((response) => {
          this.saludo = response.data['saludo']
          console.log(response.data)
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
})
</script>

