<template>
  <br>
  <div>
    <n-breadcrumb>
      <n-breadcrumb-item href="#menu-monitores">Monitors</n-breadcrumb-item>
      <n-breadcrumb-item>Food Safety</n-breadcrumb-item>
    </n-breadcrumb>
  </div>

  <div style="text-align: center;">

    <n-grid :x-gap="12" :y-gap="8" :cols="3">
      <n-grid-item>
        <div>
          <h1>Monitors <br>Food Safety</h1>
          <br>
        </div>
      </n-grid-item>
      <n-grid-item :span="2">
        <div>
          <n-divider>
            <h3>Chile</h3>
          </n-divider>
        </div>
        <div>
          <div>
            <a style="text-decoration: none; color: inherit;"
              :href="'https://tableau.hortifrut.com/#/workbooks/2897?:origin=card_share_link'" target="_blank">
              Cumplimento de Indicadores semanales Campos Chile &ensp; <n-icon size="12">
                <ExternalLinkAlt />
              </n-icon>
            </a>
          </div>
          <br>
          <div>
            <a style="text-decoration: none; color: inherit;"
              :href="'https://tableau.hortifrut.com/#/workbooks/3899?:origin=card_share_link'" target="_blank">
              Cumplimiento Indicadores semanales Packing Chile &ensp; <n-icon size="12">
                <ExternalLinkAlt />
              </n-icon>
            </a>
          </div>
          <br>

          <div>
            <a style="text-decoration: none; color: inherit;"
              :href="'https://tableau.hortifrut.com/views/BalancedeMasaAuditora/BalancedeMasa-Auditoras/fcaa2739-9fef-4820-a577-8a751192b1ad/be340aa6-12cf-4110-8412-79d39ea3cd9d?:display_count=n&:showVizHome=n&:origin=viz_share_link'"
              target="_blank">
              Balance de masa Chile &ensp; <n-icon size="12">
                <ExternalLinkAlt />
              </n-icon>
            </a>
          </div>
          <div><n-divider>
              <h3>México</h3>
            </n-divider></div>
          <div>
            <a style="text-decoration: none; color: inherit;"
              :href="'https://tableau.hortifrut.com/#/workbooks/3785?:origin=card_share_link'" target="_blank">
              Cumplimento de Indicadores semanales Campos México &ensp; <n-icon size="12">
                <ExternalLinkAlt />
              </n-icon>
            </a>
          </div>
          <br>
          <div>
            <a style="text-decoration: none; color: inherit;"
              :href="'https://tableau.hortifrut.com/views/AnalisisdeResultadosATP/AnalisisdeResultadosATP/ed5b3d49-9f49-4230-843f-ae27a0e11b22/f73abe66-862f-4829-a6bb-94f1551f59c1?:display_count=n&:showVizHome=n&:origin=viz_share_link'"
              target="_blank">
              Cumplimiento Indicadores semanales Packing México (1) &ensp; <n-icon size="12">
                <ExternalLinkAlt />
              </n-icon>
            </a>
          </div>
          <br>
          <div>
            <a style="text-decoration: none; color: inherit;"
              :href="'https://tableau.hortifrut.com/views/AnalisisdeMuestreoMicrobiolgico/AnalisisdeMuestreoMicrobiologico/3c383b52-8a12-431c-8ecc-a5671655ca5e/1d96a380-70ce-4ac0-997e-c573aec6d4a1?:display_count=n&:showVizHome=n&:origin=viz_share_link'"
              target="_blank">
              Cumplimiento Indicadores semanales Packing México (2) &ensp; <n-icon size="12">
                <ExternalLinkAlt />
              </n-icon>
            </a>
          </div>
          <div>
            <n-divider>
              <h3>Perú</h3>
            </n-divider>
          </div>
          <div>
            <a style="text-decoration: none; color: inherit;"
              :href="'https://tableau.hortifrut.com/views/DASHBOARDINDICADORES-FOODSAFETY/NC-CERTI/e5224db6-f023-429c-b71a-85399ba0a18d/9c4340b3-16a7-4aad-ab17-dcb73e5cf8a2?:display_count=n&:showVizHome=n&:origin=viz_share_link'"
              target="_blank">
              Cumplimiento Indicadores semanales Packing Trujillo (1) &ensp; <n-icon size="12">
                <ExternalLinkAlt />
              </n-icon>
            </a>
          </div>
          <br>
          <div>
            <a style="text-decoration: none; color: inherit;"
              :href="'https://tableau.hortifrut.com/views/REPORTESEMANAL-FOODSAFETY/AnlisisdeResultadosATP-LNEAS/3df53763-d92e-4398-ab99-8a8774b7996f/2b9042db-164f-4940-8fe4-ccb07a5e641b?:display_count=n&:showVizHome=n&:origin=viz_share_link'"
              target="_blank">
              Cumplimiento Indicadores semanales Packing Trujillo (2) &ensp; <n-icon size="12">
                <ExternalLinkAlt />
              </n-icon>
            </a>
          </div>
          <br>
          <div>
            <a style="text-decoration: none; color: inherit;"
              :href="'https://tableau.hortifrut.com/views/REPORTESEM-FSFUNDO/1_CAPA/11ed6370-2abe-4e21-9b7d-d773f41f19c8/7f2ccb36-2302-4d4c-b570-38ee8b8b3c27?:display_count=n&:showVizHome=n&:origin=viz_share_link'"
              target="_blank">
              Cumplimento de Indicadores semanales Campos Trujillo &ensp; <n-icon size="12">
                <ExternalLinkAlt />
              </n-icon>
            </a>
          </div>
          <br>
          <div>
            <a style="text-decoration: none; color: inherit;"
              :href="'https://tableau.hortifrut.com/views/KPI-FOODSAFE/ATPLAVADODEMANOS?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link'"
              target="_blank">
              Cumplimiento Indicadores semanales Olmos &ensp; <n-icon size="12">
                <ExternalLinkAlt />
              </n-icon>
            </a>
          </div>
          <n-divider />
        </div>
      </n-grid-item>
    </n-grid><br><br>
    <div>
      <h5>If you do not find any process, please request access to portaloperaciones@hortifrut.com</h5>
    </div>


    <router-view></router-view>
  </div>
</template>

<script>
import { ExternalLinkAlt } from '@vicons/fa'

export default {

  name: 'monitorsFoodSafety',
  components: {
    ExternalLinkAlt
  }
}

</script>

