<template>
    <br>
    <div>
        <n-breadcrumb>
            <n-breadcrumb-item>Processes</n-breadcrumb-item>
            <n-breadcrumb-item>SAP IBP</n-breadcrumb-item>
            <n-breadcrumb-item>Containering Process</n-breadcrumb-item>
        </n-breadcrumb>
    </div>
    <h1>Containering Process</h1>
    <div style="text-align: center;">
        <n-grid :x-gap="12" :y-gap="8" :cols="1">
            <n-grid-item :span="3" style="text-align: center;">
                <n-divider />
                <n-button type="primary" @click="ejecutar_cuadratura"> Run Containering Process</n-button>
                <n-divider />
            </n-grid-item>
            <n-grid-item :span="3" style="text-align: center;">
                <div v-if="show_alert_error == true">
                    <n-alert title="Error" type="error" closable>
                        <pre>{{ message_alert }}</pre>
                    </n-alert>
                </div>
                <div v-if="show_alert_success == true">
                    <n-alert title="Ok!" type="success" closable>
                        <pre>{{ message_alert }}</pre>
                    </n-alert>
                </div>
                <n-divider />
            </n-grid-item>

        </n-grid>

    </div>
    <div style="height: 500px; border: solid 1px #ddd;">
        <c-grid :data="records" :frozen-col-count="1">
            <c-grid-input-column field="Id" width="37%" min-width="150">
                Id
            </c-grid-input-column>
            <c-grid-input-column field="StartedOn" width="20%" min-width="150">
                Started On
            </c-grid-input-column>
            <c-grid-input-column field="CompletedOn" width="20%" min-width="150">
                Completed On
            </c-grid-input-column>
            <c-grid-input-column field="ExecutionTime" width="18%" min-width="150">
                Execution Time
            </c-grid-input-column>
            <c-grid-input-column field="JobRunState" width="15%" min-width="150">
                Job Run State
            </c-grid-input-column>
        </c-grid>
    </div>
    <div>
        <h5>If you do not find any process, please request access to portaloperaciones@hortifrut.com</h5>
    </div>
</template>

<script>
import axios from 'axios'
// import UnderConstruction from '@/components/UnderConstruction.vue'
import { ref } from "vue";

export default {
    name: "CuadraturaContenedores",
    mounted() {
        this.obtenerEstadoProcesosGlue();
    },
    data() {
        return {
            records: [],
            saludo: "",
            nombre: "",
            isDisable: false,
            show_alert_success: ref(null),
            show_alert_error: ref(null),
            message_alert: ref(null)
        };
    },
    methods: {
        obtenerEstadoProcesosGlue() {
            axios.get("https://14u51m5kzc.execute-api.us-west-2.amazonaws.com/hf-prod-stage/universo-factible/ejecutar-analisis/obtener-ejecuciones" + "?jobName=hfch-ibp-analisisCuadraturaContenedores")
                .then((response) => {
                    console.log(response.data.JobRuns);
                    this.records = response.data.JobRuns;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        ejecutar_cuadratura() {
            axios.put("https://14u51m5kzc.execute-api.us-west-2.amazonaws.com/hf-prod-stage/cuadratura-contenedores/ejecutar-proceso")
            .then((response) => {
                    console.log(response);
                    this.show_alert_success = true
                    this.message_alert = "JobRunId: " + response.data.JobRunId
                    this.obtenerEstadoProcesosGlue()
                }).catch((error) => {
                    this.show_alert_error = true
                    console.log(error);
                });
        },
    }
}
</script>


<style>

</style>