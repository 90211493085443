<template>
 <br>
  <div>
    <n-breadcrumb>
      <n-breadcrumb-item>Procesos</n-breadcrumb-item>
      <n-breadcrumb-item>Operaciones</n-breadcrumb-item>
      <n-breadcrumb-item>Revenue Management</n-breadcrumb-item>
    </n-breadcrumb>
  </div>

<h1>Revenue Management</h1>
</template>

<script>
import axios from 'axios'
export default {
  name: 'RevenueManagement',
  data() {
    return {
        result: [],
        saludo: "Hola",
        nombre: ""

    }
  },
  methods : {
    api() {
        
        axios.get('https://14u51m5kzc.execute-api.us-west-2.amazonaws.com/hf-prod-stage/dummy/hola-mundo?param='+ this.nombre)
            .then((response) => {
                this.saludo = response.data['saludo']
                console.log(response.data)
            })
            .catch((error) => {
                console.log(error)
            })
    },
  },
}
</script>

