<template>
  <br>
  <div>
    <n-breadcrumb>
      <n-breadcrumb-item href="#menu-monitores">Monitors</n-breadcrumb-item>
      <n-breadcrumb-item>Business Planning</n-breadcrumb-item>
    </n-breadcrumb>
  </div>

  <div style="text-align: center;">

    <n-grid :x-gap="12" :y-gap="8" :cols="3">
      <n-grid-item>
        <div>
          <h1>Monitors <br>Business Planning</h1>
          <!-- <h4>Estado y Ejecuciones</h4> -->
          <br>
        </div>
      </n-grid-item>
      <n-grid-item :span="2">

        <div>
          <n-divider>
            <h3>Industry</h3>
          </n-divider>
          <div>
            <a style="text-decoration: none; color: inherit;"
              :href="'https://tableau.hortifrut.com/#/views/Proarandanos/PROARANDANOS?:iid=2'" target="_blank">
              Proarandanos Conventional Perú &ensp; <n-icon size="12">
                <ExternalLinkAlt />
              </n-icon>
            </a>
          </div>
          <div>
            <a style="text-decoration: none; color: inherit;"
              :href="'https://tableau.hortifrut.com/#/views/Proarandanos-organic/Proarandanos-organic?:iid=2'"
              target="_blank">
              Proarandanos Organic Perú &ensp; <n-icon size="12">
                <ExternalLinkAlt />
              </n-icon>
            </a>
          </div>
          <div>
            <a style="text-decoration: none; color: inherit;"
              :href="'https://tableau.hortifrut.com/#/views/ASOEX1920-2021/ASOEXBLUES?:iid=2'" target="_blank">
              ASOEX Blueberries Chile &ensp; <n-icon size="12">
                <ExternalLinkAlt />
              </n-icon>
            </a>
          </div>
          <div>
            <a style="text-decoration: none; color: inherit;"
              :href="'https://tableau.hortifrut.com/#/views/ASOEXCHERRIES/ASOEXCHERRIESBUDGET?:iid=1'" target="_blank">
              ASOEX Cherries Chile &ensp; <n-icon size="12">
                <ExternalLinkAlt />
              </n-icon>
            </a>
          </div>
          <div>
            <a style="text-decoration: none; color: inherit;"
              :href="'https://tableau.hortifrut.com/#/views/Veritrade/Veritrade?:iid=2'" target="_blank">
              Veritrade &ensp; <n-icon size="12">
                <ExternalLinkAlt />
              </n-icon>
            </a>
          </div>
          <div>
            <a style="text-decoration: none; color: inherit;"
              :href="'https://tableau.hortifrut.com/#/views/USDA/USDA?:iid=1'" target="_blank">
              USDA &ensp; <n-icon size="12">
                <ExternalLinkAlt />
              </n-icon>
            </a>
          </div>
          <div>
            <a style="text-decoration: none; color: inherit;"
              :href="'https://tableau.hortifrut.com/#/views/FreshCargo/FRESHCARGO?:iid=1'" target="_blank">
              Freshcargo &ensp; <n-icon size="12">
                <ExternalLinkAlt />
              </n-icon>
            </a>
          </div>
          <div>
            <a style="text-decoration: none; color: inherit;"
              :href="'https://tableau.hortifrut.com/#/views/Industrysummary/INDUSTRYSUMMARY?:iid=1'" target="_blank">
              Industry Summary &ensp; <n-icon size="12">
                <ExternalLinkAlt />
              </n-icon>
            </a>
          </div>
          <n-divider>
            <h3>Industry - Asia</h3>
          </n-divider>
          <div>
            <a style="text-decoration: none; color: inherit;"
              :href="'https://tableau.hortifrut.com/#/views/BenchmarkChina/BENCHMARKCHINA'" target="_blank">
              Benchmark China &ensp; <n-icon size="12">
                <ExternalLinkAlt />
              </n-icon>
            </a>
          </div>
          <div>
            <a style="text-decoration: none; color: inherit;"
              :href="'https://tableau.hortifrut.com/#/views/BenchmarkYunnan/BENCHMARKYUNNAN?:iid=1'" target="_blank">
              Benchmark Yunnan &ensp; <n-icon size="12">
                <ExternalLinkAlt />
              </n-icon>
            </a>
          </div>
          <div>
            <a style="text-decoration: none; color: inherit;"
              :href="'https://tableau.hortifrut.com/#/views/BenchmarkCherriesChina/BENCHMARKCHERRIESCHINA?:iid=2'"
              target="_blank">
              Benchmark Cherries China &ensp; <n-icon size="12">
                <ExternalLinkAlt />
              </n-icon>
            </a>
          </div>
          <n-divider>
            <h3>Sales</h3>
          </n-divider>
          <div>
            <a style="text-decoration: none; color: inherit;"
              :href="'https://tableau.hortifrut.com/#/views/MarketssummaryFOB-CIF-Sales/Marketssummary?:iid=3'"
              target="_blank">
              Market Summary &ensp; <n-icon size="12">
                <ExternalLinkAlt />
              </n-icon>
            </a>
          </div>
          <div>
            <a style="text-decoration: none; color: inherit;"
              :href="'https://tableau.hortifrut.com/#/views/InformacinClientes/CUSTOMERS?:iid=5'" target="_blank">
              Customers information Platform: EU/UK &ensp; <n-icon size="12">
                <ExternalLinkAlt />
              </n-icon>
            </a>
          </div>
          <div>
            <a style="text-decoration: none; color: inherit;"
              :href="'https://tableau.hortifrut.com/#/views/PricevsBenchmark/KPISCOMERCIALES?:iid=9'" target="_blank">
              Price vs Benchmark &ensp; <n-icon size="12">
                <ExternalLinkAlt />
              </n-icon>
            </a>
          </div>
          <div>
            <a style="text-decoration: none; color: inherit;"
              :href="'https://tableau.hortifrut.com/#/views/CustomersInformation/CUSTOMERS?:iid=6'" target="_blank">
              Customers information Platform: Asia &ensp; <n-icon size="12">
                <ExternalLinkAlt />
              </n-icon>
            </a>
          </div>
          <div>
            <a style="text-decoration: none; color: inherit;"
              :href="'https://tableau.hortifrut.com/#/views/PricingAccuracy/Pricingforecasts?:iid=4'" target="_blank">
              Pricing Accuracy &ensp; <n-icon size="12">
                <ExternalLinkAlt />
              </n-icon>
            </a>
          </div>
          <div>
            <a style="text-decoration: none; color: inherit;"
              :href="'https://tableau.hortifrut.com/#/views/MarketssummaryFOB-CIF-Sales/Marketsspecifics?:iid=3'"
              target="_blank">
              Market Specifics &ensp; <n-icon size="12">
                <ExternalLinkAlt />
              </n-icon>
            </a>
          </div>
          <div>
            <a style="text-decoration: none; color: inherit;"
              :href="'https://tableau.hortifrut.com/#/views/SalesAnalyticsUSACANADAv2/SALESANALYTICSUSACANADA?:iid=10'"
              target="_blank">
              Sales analytics Platform: USA &ensp; <n-icon size="12">
                <ExternalLinkAlt />
              </n-icon>
            </a>
          </div>
          <div>
            <a style="text-decoration: none; color: inherit;"
              :href="'https://tableau.hortifrut.com/#/views/SalesAnalyticsAsiaAllorigins/SALESANALYTICSASIA?:iid=6'"
              target="_blank">
              Sales analytics (Budget) Platform: Asia &ensp; <n-icon size="12">
                <ExternalLinkAlt />
              </n-icon>
            </a>
          </div>
          <div>
            <a style="text-decoration: none; color: inherit;"
              :href="'https://tableau.hortifrut.com/#/views/SALESANALYTICSEUROPA/SALESANALYTICSEUROPA?:iid=11'"
              target="_blank">
              Sales analytics Platform: EU &ensp; <n-icon size="12">
                <ExternalLinkAlt />
              </n-icon>
            </a>
          </div>
          <div>
            <a style="text-decoration: none; color: inherit;"
              :href="'https://tableau.hortifrut.com/#/views/SALESANALYTICSUK/SALESANALYTICSUK?:iid=1'" target="_blank">
              Sales analytics Platform: UK &ensp; <n-icon size="12">
                <ExternalLinkAlt />
              </n-icon>
            </a>
          </div>
          <div>
            <a style="text-decoration: none; color: inherit;"
              :href="'https://tableau.hortifrut.com/#/views/SALESANALYTICSASIAV2/SALESANALYTICS?:iid=5'"
              target="_blank">
              Sales analytics Platform: Asia &ensp; <n-icon size="12">
                <ExternalLinkAlt />
              </n-icon>
            </a>
          </div>
          <div>
            <a style="text-decoration: none; color: inherit;"
              :href="'https://tableau.hortifrut.com/#/views/LiquidacionesChina_16450303357740/CHINASHIPMENTS'"
              target="_blank">
              Liquidaciones China Platform: Asia &ensp; <n-icon size="12">
                <ExternalLinkAlt />
              </n-icon>
            </a>
          </div>
          <div>
            <a style="text-decoration: none; color: inherit;"
              :href="'https://tableau.hortifrut.com/#/views/ComercialT21-22V3HFCO/COMERCIAL?:iid=1'" target="_blank">
              HFCO Comercial Fruta Fresca T22/23 Platform: LATAM &ensp; <n-icon size="12">
                <ExternalLinkAlt />
              </n-icon>
            </a>
          </div>
          <div>
            <a style="text-decoration: none; color: inherit;"
              :href="'https://tableau.hortifrut.com/#/workbooks/1256/views'" target="_blank">
              Volumenes-precios vs Industria/Benchmark EU Platform: EU/UK &ensp; <n-icon size="12">
                <ExternalLinkAlt />
              </n-icon>
            </a>
          </div>
          <div>
            <a style="text-decoration: none; color: inherit;"
              :href="'https://tableau.hortifrut.com/#/views/ChinaWeeklysummary/ChinaWeeklySummary?:iid=2'"
              target="_blank">
              China Weekly Summary &ensp; <n-icon size="12">
                <ExternalLinkAlt />
              </n-icon>
            </a>
          </div>
          <div>
            <a style="text-decoration: none; color: inherit;"
              :href="'https://tableau.hortifrut.com/#/views/EBM-EUWeeklySummary/EBMWeeklysummary?:iid=1'"
              target="_blank">
              EBM Weekly Summary &ensp; <n-icon size="12">
                <ExternalLinkAlt />
              </n-icon>
            </a>
          </div>

          <n-divider>
            <h3>
              Stocks
            </h3>
          </n-divider>
          <div>
            <a style="text-decoration: none; color: inherit;"
              :href="'https://tableau.hortifrut.com/#/views/EBMStockageanalysis/EBMStocksKGs?:iid=4'"
              target="_blank">
              EBM Stocks &ensp; <n-icon size="12">
                <ExternalLinkAlt />
              </n-icon>
            </a>
          </div>
          <div>
            <a style="text-decoration: none; color: inherit;"
              :href="'https://tableau.hortifrut.com/#/views/NRFStockageanalysis/NRFStocksKGs?:iid=3'"
              target="_blank">
              NRF Stocks &ensp; <n-icon size="12">
                <ExternalLinkAlt />
              </n-icon>
            </a>
          </div>
          <n-divider />
        </div>
      </n-grid-item>
    </n-grid><br><br>
    <div>
      <h5>If you do not find any process, please request access to portaloperaciones@hortifrut.com</h5>
    </div>


    <router-view></router-view>
  </div>
</template>

<script>
import { ExternalLinkAlt } from '@vicons/fa'

export default {

  name: 'monitorsBusinessPlann',
  components: {
    ExternalLinkAlt
  }
}

</script>

