<template>
  <br>
  <div>
    <n-breadcrumb>
      <n-breadcrumb-item href="#menudatos">Data</n-breadcrumb-item>
      <n-breadcrumb-item>Official Formats</n-breadcrumb-item>
    </n-breadcrumb>
  </div>

  <div style="text-align: center;">
    <h1>Official Formats</h1>
    <n-tabs type="line" animated>
      <n-tab-pane name="costo-packing" tab="Packing Cost">
        <n-grid :x-gap="12" :y-gap="8" :cols="12">
          <n-grid-item :span="12">
            <h2>Packing Cost</h2>
          </n-grid-item>
          <n-grid-item :span="2" />
          <n-grid-item :span="2">
            <div>
              <n-card class="special_hover" title="Packing Cost" hoverable
                @click="descargarFormato('costo-packing/CTOPACKING_HFBP-WXX.xlsm')">
                HFBP
                <n-icon size="12">
                  <Download />
                </n-icon>
              </n-card>
            </div>
          </n-grid-item>
          <n-grid-item :span="2">
            <n-card class="special_hover" title="Packing Cost" hoverable
              @click="descargarFormato('costo-packing/CTOPACKING_HFCH-WXX.xlsm')">
              HFCH
              <n-icon size="12">
                <Download />
              </n-icon>
            </n-card>
          </n-grid-item>
          <n-grid-item :span="2">
            <n-card class="special_hover" title="Packing Cost" hoverable
              @click="descargarFormato('costo-packing/CTOPACKING_HFEX-WXX.xlsm')">
              HFEX
              <n-icon size="12">
                <Download />
              </n-icon>
            </n-card>
          </n-grid-item>
          <n-grid-item :span="2">
            <n-card class="special_hover" title="Packing Cost" hoverable
              @click="descargarFormato('costo-packing/CTOPACKING_HFPE-WXX.xlsm')">
              HFPE
              <n-icon size="12">
                <Download />
              </n-icon>
            </n-card>
          </n-grid-item>
          <n-grid-item :span="2" />
          <n-grid-item :span="12">
            <div>
              <n-divider />
            </div>
            <h2> Real Packing Cost</h2>
          </n-grid-item>
          <n-grid-item :span="2" />
          <n-grid-item :span="2">
            <n-card class="special_hover" title="Real Packing Cost" hoverable
              @click="descargarFormato('costo-packing/CTOPACKING_REAL_HFBP-WXX.xlsm')">
              HFBP
              <n-icon size="12">
                <Download />
              </n-icon>
            </n-card>
          </n-grid-item>
          <n-grid-item :span="2">
            <n-card class="special_hover" title="Real Packing Cost" hoverable
              @click="descargarFormato('costo-packing/CTOPACKING_REAL_HFCH-WXX.xlsm')">
              HFCH
              <n-icon size="12">
                <Download />
              </n-icon>
            </n-card>
          </n-grid-item>
          <n-grid-item :span="2">
            <n-card class="special_hover" title="Real Packing Cost" hoverable
              @click="descargarFormato('costo-packing/CTOPACKING_REAL_HFEX-WXX.xlsm')">
              HFEX
              <n-icon size="12">
                <Download />
              </n-icon>
            </n-card>
          </n-grid-item>
          <n-grid-item :span="2">
            <n-card class="special_hover" title="Real Packing Cost" hoverable
              @click="descargarFormato('costo-packing/CTOPACKING_REAL_HFPE-WXX.xlsm')">
              HFPE
              <n-icon size="12">
                <Download />
              </n-icon>
            </n-card>
          </n-grid-item>
          <n-grid-item :span="2" />
        </n-grid><br><br>
      </n-tab-pane>
      <n-tab-pane name="costo-cosecha" tab="Harvest Cost">
        <n-grid :x-gap="12" :y-gap="8" :cols="12">
          <n-grid-item :span="12">
            <h2>Harvest Cost</h2>
          </n-grid-item>
          <n-grid-item :span="2" />
          <n-grid-item :span="2">
            <div>
              <n-card class="special_hover" title="Harvest Cost" hoverable
                @click="descargarFormato('costo-cosecha/CTOCOSECHA_HFBP-WXX.xlsm')">
                HFBP
                <n-icon size="12">
                  <Download />
                </n-icon>
              </n-card>
            </div>
          </n-grid-item>
          <n-grid-item :span="2">
            <n-card class="special_hover" title="Harvest Cost" hoverable
              @click="descargarFormato('costo-cosecha/CTOCOSECHA_HFCH-WXX.xlsm')">
              HFCH
              <n-icon size="12">
                <Download />
              </n-icon>
            </n-card>
          </n-grid-item>
          <n-grid-item :span="2">
            <n-card class="special_hover" title="Harvest Cost" hoverable
              @click="descargarFormato('costo-cosecha/CTOCOSECHA_HFEX-WXX.xlsm')">
              HFEX
              <n-icon size="12">
                <Download />
              </n-icon>
            </n-card>
          </n-grid-item>
          <n-grid-item :span="2">
            <n-card class="special_hover" title="Harvest Cost" hoverable
              @click="descargarFormato('costo-cosecha/CTOCOSECHA_HFPE-WXX.xlsm')">
              HFPE
              <n-icon size="12">
                <Download />
              </n-icon>
            </n-card>
          </n-grid-item>
          <n-grid-item :span="2" />
          <n-grid-item :span="12">
            <div>
              <n-divider />
            </div>
            <h2>Real Harvest Cost</h2>
          </n-grid-item>
          <n-grid-item :span="2" />
          <n-grid-item :span="2">
            <n-card class="special_hover" title="Real Harvest Cost" hoverable
              @click="descargarFormato('costo-cosecha/CTOCOSECHA_REAL_HFBP-WXX.xlsm')">
              HFBP
              <n-icon size="12">
                <Download />
              </n-icon>
            </n-card>
          </n-grid-item>
          <n-grid-item :span="2">
            <n-card class="special_hover" title="Real Harvest Cost" hoverable
              @click="descargarFormato('costo-cosecha/CTOCOSECHA_REAL_HFCH-WXX.xlsm')">
              HFCH
              <n-icon size="12">
                <Download />
              </n-icon>
            </n-card>
          </n-grid-item>
          <n-grid-item :span="2">
            <n-card class="special_hover" title="Real Harvest Cost" hoverable
              @click="descargarFormato('costo-cosecha/CTOCOSECHA_REAL_HFEX-WXX.xlsm')">
              HFEX
              <n-icon size="12">
                <Download />
              </n-icon>
            </n-card>
          </n-grid-item>
          <n-grid-item :span="2">
            <n-card class="special_hover" title="Real Harvest Cost" hoverable
              @click="descargarFormato('costo-cosecha/CTOCOSECHA_REAL_HFPE-WXX.xlsm')">
              HFPE
              <n-icon size="12">
                <Download />
              </n-icon>
            </n-card>
          </n-grid-item>
          <n-grid-item :span="2" />
        </n-grid><br><br>
      </n-tab-pane>
      <n-tab-pane name="costo-prod-fijo" tab="Demand">
        <n-grid :x-gap="12" :y-gap="8" :cols="12">
          <n-grid-item :span="12">
            <h2>Demand</h2>
          </n-grid-item>
          <n-grid-item :span="2" />
          <n-grid-item :span="2">
            <div>
              <n-card class="special_hover" title="Packplan Asia" hoverable
                @click="descargarFormato('demanda/PACKPLAN_ASIA IBP WXX.xlsm')">
                ASIA
                <n-icon size="12">
                  <Download />
                </n-icon>
              </n-card>
            </div>
          </n-grid-item>
          <n-grid-item :span="2">
            <n-card class="special_hover" title="Packplan China" hoverable
              @click="descargarFormato('demanda/PACKPLAN_CHINA_IBP WXX.xlsm')">
              CHINA
              <n-icon size="12">
                <Download />
              </n-icon>
            </n-card>
          </n-grid-item>
          <n-grid-item :span="2">
            <n-card class="special_hover" title="Packplan EBM Ultramar" hoverable
              @click="descargarFormato('demanda/PACKPLAN_EBM_ULTRAMAR-WXX.xlsm')">
              EBM
              <n-icon size="12">
                <Download />
              </n-icon>
            </n-card>
          </n-grid-item>
          <n-grid-item :span="2">
            <n-card class="special_hover" title="Packplan EBUK Ultramar" hoverable
              @click="descargarFormato('demanda/PACKPLAN_EBUK_ULTRAMAR-WXX.xlsm')">
              EBUK
              <n-icon size="12">
                <Download />
              </n-icon>
            </n-card>
          </n-grid-item>
          <n-grid-item :span="2" />
        </n-grid><br><br>
      </n-tab-pane>
    </n-tabs>

    <div>
      <h5>If you do not find any process, please request access to portaloperaciones@hortifrut.com</h5>
    </div>


    <router-view></router-view>
  </div>
</template>

<script>
import { Download } from '@vicons/fa'
import axios from 'axios';
export default {
  name: 'DescargaFormatos',
  components: {
    Download
  },
  methods: {
    descargarFormato(fileName) {
      axios.get("https://14u51m5kzc.execute-api.us-west-2.amazonaws.com/hf-prod-stage/formatos-ibp/descargar-formato" + "?name=" + fileName)
        .then((response) => {
          // console.log(response.data.url);
          window.open(response.data.url, '_blank', 'noreferrer');
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
}

</script>

<style>
.special_hover {
  cursor: pointer;
}
</style>