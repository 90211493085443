<template>
  <div style="text-align: center;">
    <h1>Data</h1>
    <br>


    <n-grid :x-gap="12" :y-gap="8" :cols="5">
      <n-grid-item></n-grid-item>
      <n-grid-item>
        <div>
          <n-icon size="30"><Area /></n-icon>
          <h3>Data Catalog</h3>
        </div>
        <div>
           BW Data <br>
           AWS Data<br><br><br>
        </div>
        <br>
        <div>
          <!-- <router-link to="/menudatos/catalogo" style="text-decoration: none; color: inherit;"> -->
            <n-button type="info" disabled> Go To Catalog </n-button>
          <!-- </router-link> -->
        </div>
      </n-grid-item>
      <n-grid-item>
        <div>
          <n-icon size="30">
            <OperationsField />
          </n-icon>
          <h3>Official Formats</h3>
        </div>
        <div>
          Packplan <br>
          Origin Costs <br>
          Transportation Costs <br>
          Destination Costs <br>
        </div>
        <br>
        <div>
          <router-link to="/menudatos/formatos" style="text-decoration: none; color: inherit;">
            <n-button type="info"> Go To Formats </n-button>
          </router-link>
        </div>
      </n-grid-item>
      <n-grid-item>
        <div>
          <n-icon size="30">
            <Data1 />
          </n-icon>
          <h3>Dictionaries</h3>
        </div>
        <div>
          DM Customers<br>
          DM Locations<br>
          DM Products
          <br><br><br>
        </div>
        <div>
          <!-- <router-link to="/menudatos/diccionarios" style="text-decoration: none; color: inherit;"> -->
            <n-button type="info" disabled> Go To Dictionaries </n-button>
          <!-- </router-link> -->
        </div>
      </n-grid-item>
      <n-grid-item></n-grid-item>
    </n-grid><br><br>
    <div>
      <h5>If you do not find any process, please request access to portaloperaciones@hortifrut.com</h5>
    </div>


    <router-view></router-view>
  </div>
</template>

<script>
import { Data1, OperationsField, Area } from '@vicons/carbon'

export default {
  name: 'MenuDatos',
  components: {
    Data1, OperationsField, Area
  }
}

</script>

