<template>
  <br>
  <div>
    <n-breadcrumb>
      <n-breadcrumb-item href="#menu-monitores">Monitors</n-breadcrumb-item>
      <n-breadcrumb-item>S&OP</n-breadcrumb-item>
    </n-breadcrumb>
  </div>
  <div style="text-align: center;">
    <n-grid :x-gap="12" :y-gap="8" :cols="3">
      <n-grid-item>
        <div>
          <h1>Monitors <br>S&OP</h1>
          <!-- <h4>Estado y Ejecuciones</h4> -->
          <br>
        </div>
      </n-grid-item>
      <n-grid-item :span="2">
        <div>
          <n-divider>
            <h3> Corporativos</h3>
          </n-divider>
          <div>
            <a style="text-decoration: none; color: inherit;"
              :href="'https://tableau.hortifrut.com/#/workbooks/3919/views'" target="_blank">
              IBP Output Analysis &ensp; <n-icon size="12">
                <ExternalLinkAlt />
              </n-icon>
            </a>
          </div>
          <div>
            <a style="text-decoration: none; color: inherit;"
              :href="'https://tableau.hortifrut.com/#/workbooks/2894/views'" target="_blank">
              Análisis por Filial - Programa Comercial &ensp; <n-icon size="12">
                <ExternalLinkAlt />
              </n-icon>
            </a>
          </div>
          <div>
            <a style="text-decoration: none; color: inherit;"
              :href="'https://tableau.hortifrut.com/#/workbooks/2846/views'" target="_blank">
              Análisis Programa Comercial – Presupuesto &ensp; <n-icon size="12">
                <ExternalLinkAlt />
              </n-icon>
            </a>
          </div>
          <div>
            <a style="text-decoration: none; color: inherit;"
              :href="'https://tableau.hortifrut.com/#/workbooks/3195/views'" target="_blank">
              PxQ Perú &ensp; <n-icon size="12">
                <ExternalLinkAlt />
              </n-icon>
            </a>
          </div>
          <div>
            <a style="text-decoration: none; color: inherit;"
              :href="'https://tableau.hortifrut.com/#/workbooks/3194/views'" target="_blank">
              Fill Rate S&OP Perú &ensp; <n-icon size="12">
                <ExternalLinkAlt />
              </n-icon>
            </a>
          </div>
          <n-divider>
            <h3> Trujillo</h3>
          </n-divider>
          <div>
            <a style="text-decoration: none; color: inherit;"
              :href="'https://tableau.hortifrut.com/#/workbooks/2902/views'" target="_blank">
              Análisis MRP &ensp; <n-icon size="12">
                <ExternalLinkAlt />
              </n-icon>
            </a>
          </div>
          <div>
            <a style="text-decoration: none; color: inherit;"
              :href="'https://tableau.hortifrut.com/#/workbooks/3109/views'" target="_blank">
              Trade Off &ensp; <n-icon size="12">
                <ExternalLinkAlt />
              </n-icon>
            </a>
          </div>
          <div>
            <a style="text-decoration: none; color: inherit;"
              :href="'https://tableau.hortifrut.com/#/workbooks/2904/views'" target="_blank">
              Control de Empaque Diario &ensp; <n-icon size="12">
                <ExternalLinkAlt />
              </n-icon>
            </a>
          </div>
          <div>
            <a style="text-decoration: none; color: inherit;"
              :href="'https://tableau.hortifrut.com/#/workbooks/2918/views'" target="_blank">
              Fill Rate S&OP HFPE &ensp; <n-icon size="12">
                <ExternalLinkAlt />
              </n-icon>
            </a>
          </div>
          <div>
            <a style="text-decoration: none; color: inherit;"
              :href="'https://tableau.hortifrut.com/#/workbooks/2896/views'" target="_blank">
              Forecast Accuracy &ensp; <n-icon size="12">
                <ExternalLinkAlt />
              </n-icon>
            </a>
          </div>
          <div>
            <a style="text-decoration: none; color: inherit;"
              :href="'https://tableau.hortifrut.com/#/workbooks/2921/views'" target="_blank">
              PxQ HFPE &ensp; <n-icon size="12">
                <ExternalLinkAlt />
              </n-icon>
            </a>
          </div>
          <n-divider>
            <h3>Olmos</h3>
          </n-divider>
          <div>
            <a style="text-decoration: none; color: inherit;"
              :href="'https://tableau.hortifrut.com/#/workbooks/3170/views'" target="_blank">
              PxQ HFBP &ensp; <n-icon size="12">
                <ExternalLinkAlt />
              </n-icon>
            </a>
          </div>
          <div>
            <a style="text-decoration: none; color: inherit;"
              :href="'https://tableau.hortifrut.com/#/workbooks/3001/views'" target="_blank">
              KPI’s S&OP HFBP &ensp; <n-icon size="12">
                <ExternalLinkAlt />
              </n-icon>
            </a>
          </div>
          <n-divider />
        </div>
      </n-grid-item>
    </n-grid><br><br>
    <div>
      <h5>If you do not find any process, please request access to portaloperaciones@hortifrut.com</h5>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import { ExternalLinkAlt } from '@vicons/fa'

export default {
  name: 'monitorsSOP',
  components: {
    ExternalLinkAlt
  }
}

</script>

