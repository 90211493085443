import { createRouter, createWebHashHistory } from 'vue-router'
import CuadraturaContenedores from '@/components/menu-procesos/sap-ibp/CuadraturaContenedores.vue'
import RevenueManagement from '@/components/menu-procesos/operaciones/RevenueManagement.vue'
import UniversoFactible from '@/components/menu-procesos/sap-ibp/UniversoFactible.vue'
import MenuProcesos from '@/components/menu-procesos/MenuProcesos.vue'
import MenuOperaciones from '@/components/menu-procesos/operaciones/MenuOperaciones.vue'
import UniversoTotal from '@/components/menu-procesos/sap-ibp/UniversoTotal.vue'
import TranspCostRateAgg from '@/components/menu-procesos/sap-ibp/TranspCostRateAgg.vue'
import MainMenu from '@/components/MainMenu.vue'
import MenuDatos from '@/components/menu-datos/MenuDatos.vue'
import DescargaFormatos from '@/components/menu-datos/menu-formatos/DescargaFormatos.vue'
import MenuMonitores from '@/components/menu-monitores/MenuMonitores.vue'
import MenuSoporte from '@/components/menu-soporte/MenuSoporte.vue'
import MenuIBP from '@/components/menu-procesos/sap-ibp/MenuIBP.vue'

import MonitoresIBP from '@/components/menu-monitores/ibp/MonitoresIBP.vue'

import MonitorsBusinessPlanning from '@/components/menu-monitores/MonitorsBusinessPlanning.vue'
import MonitorsContinuousImprovement from '@/components/menu-monitores/MonitorsContinuousImprovement.vue'
import MonitorsExports from '@/components/menu-monitores/MonitorsExports.vue'
import MonitorsFoodSafety from '@/components/menu-monitores/MonitorsFoodSafety.vue'
import MonitorsImports from '@/components/menu-monitores/MonitorsImports.vue'
import MonitorsOperations from '@/components/menu-monitores/MonitorsOperations.vue'
import MonitorsProcurementAssistant from '@/components/menu-monitores/MonitorsProcurementAssistant.vue'
import MonitorsQuality from '@/components/menu-monitores/MonitorsQuality.vue'
import MonitorsSOP from '@/components/menu-monitores/MonitorsSOP.vue'

import MonitoresOperCorp from '@/components/menu-monitores/operaciones-corp/MonitoresOperCorp.vue'
import MonitoresSOP from '@/components/menu-monitores/sop/MonitoresSOP.vue'
import LoginAzureAD from '@/components/LoginAzureAD.vue'
import UnderConstruction from '@/components/UnderConstruction.vue'

// import aad from "@/services/aad";

const routes = [
  { path: '/', component: MainMenu, name: 'MainMenu', meta: { title: 'Portal Operaciones' }},
  { path: '/login', component: LoginAzureAD, name: 'LoginAzureAD' },
  
  { path: '/menuprocesos', component: MenuProcesos, name: 'MenuProcesos' },
  { path: '/menuprocesos/menuIBP', component: MenuIBP, name: 'MenuIBP' },
  { path: '/menuprocesos/menuIBP/universototal', component: UniversoTotal, name: 'UniversoTotal', meta: { requiresAuth: true }},
  { path: '/menuprocesos/menuIBP/universofactible', component: UniversoFactible, name: 'UniversoFactible', meta: { requiresAuth: true }},
  { path: '/menuprocesos/menuIBP/cuadraturacontenedores', component: CuadraturaContenedores, name: 'CuadraturaContenedores' },
  { path: '/menuprocesos/menuIBP/transpcostrateagg', component: TranspCostRateAgg, name: 'TranspCostRateAgg' },
  
  { path: '/menuprocesos/menuoperaciones', component: MenuOperaciones, name: 'MenuOperaciones' },
  { path: '/menuprocesos/menuoperaciones/revenuemanagement', component: RevenueManagement, name: 'RevenueManagement' },
  { path: '/menuprocesos/menuoperaciones/consolidatedliquidations', component: UnderConstruction, name: 'UnderConstruction' },
  { path: '/menuprocesos/menuoperaciones/valuedcorporatepl', component: UnderConstruction, name: 'UnderConstruction' },
  
  { path: '/menudatos', component: MenuDatos, name: 'MenuDatos' },
  { path: '/menudatos/formatos', component: DescargaFormatos, name: 'DescargaFormatos' },

  { path: '/menu-monitores', component: MenuMonitores, name: 'MenuMonitores' },
  { path: '/menu-monitores/monitores-ibp', component: MonitoresIBP, name: 'monitores-ibp' },
  { path: '/menu-monitores/monitores-operaciones', component: MonitoresOperCorp, name: 'monitores-operaciones' },
  { path: '/menu-monitores/monitores-sop', component: MonitoresSOP, name: 'monitores-sop' },
  
  
  { path: '/menu-monitores/monitors-business-plannn', component: MonitorsBusinessPlanning, name: 'MonitorsBusinessPlanning' },
  { path: '/menu-monitores/monitors-cont-improv', component: MonitorsContinuousImprovement, name: 'MonitorsContinuousImprovement' },
  { path: '/menu-monitores/monitors-exports', component: MonitorsExports, name: 'MonitorsExports' },
  { path: '/menu-monitores/monitors-food-safety', component: MonitorsFoodSafety, name: 'MonitorsFoodSafety' },
  { path: '/menu-monitores/monitors-imports', component: MonitorsImports, name: 'MonitorsImports' },
  { path: '/menu-monitores/monitors-operations', component: MonitorsOperations, name: 'MonitorsOperations' },
  { path: '/menu-monitores/monitors-procur-assist', component: MonitorsProcurementAssistant, name: 'MonitorsProcurementAssistant' },
  { path: '/menu-monitores/monitors-quality', component: MonitorsQuality, name: 'MonitorsQuality' },
  { path: '/menu-monitores/monitors-sop', component: MonitorsSOP, name: 'MonitorsSOP' },

  

  { path: '/menusoporte', component: MenuSoporte, name: 'MenuSoporte' },
]

const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHashHistory(),
  routes, // short for `routes: routes`
})


router.beforeEach(async (to) => {

  // console.log('to, from:', to.name, from.name)

  let keys = Object.keys(sessionStorage);
  // console.log(keys)
  // // for (let key of keys) {
  // //   // console.log(`${key}: ${sessionStorage.getItem(key)}`);
  // //   console.log(key)
  // // }
  let isAuthenticated = false
  if (keys.length > 0) {
    isAuthenticated = true
  }

  if (to.name == 'MainMenu') {
    // redirect the user to the login page
    // console.log('Main Menu')
    // return { name: 'MainMenu' }
    return true
  }
  else if (!isAuthenticated && to.name !== 'LoginAzureAD') {
    // redirect the user to the login page
    return { name: 'LoginAzureAD' }
  }
})

export default router