<template>
  <br>
  <div>
    <n-breadcrumb>
      <n-breadcrumb-item href="#menu-monitores">Monitors</n-breadcrumb-item>
      <n-breadcrumb-item>Operations</n-breadcrumb-item>
    </n-breadcrumb>
  </div>

  <div style="text-align: center;">

    <n-grid :x-gap="12" :y-gap="8" :cols="3">
      <n-grid-item>
        <div>
          <h1>Monitors <br>Operations</h1>
          <!-- <h4>Estado y Ejecuciones</h4> -->
          <br>
        </div>
      </n-grid-item>
      <n-grid-item :span="2">

        <div>
          <n-divider />

          <div>
            <a style="text-decoration: none; color: inherit;"
              :href="'https://tableau.hortifrut.com/#/views/Balance_Olmos/Balance_Olmos?:iid=1'" target="_blank">
              Balance Olmos &ensp; <n-icon size="12">
                <ExternalLinkAlt />
              </n-icon>
            </a>
          </div>
          <n-divider />
          <div>
            <a style="text-decoration: none; color: inherit;"
              :href="'https://tableau.hortifrut.com/#/views/DeltasPackingT2223/ReporteDeltas?:iid=1'"
              target="_blank">
              Reporte deltas de Tiempo &ensp;
              <n-icon size="12">
                <ExternalLinkAlt />
              </n-icon>
            </a>
          </div>
          <n-divider />
          <div>
            <a style="text-decoration: none; color: inherit;"
              :href="'https://tableau.hortifrut.com/#/views/Prueba_16558542903220/CostoPacking?:iid=3'"
              target="_blank">
              Costos Packing &ensp;
              <n-icon size="12">
                <ExternalLinkAlt />
              </n-icon>
            </a>
          </div>
          <n-divider />
          <div>
            <a style="text-decoration: none; color: inherit;"
              :href="'https://tableau.hortifrut.com/#/views/RECEPCINEMBALADOGRANEL/RECEPCINEMBALADOGRANEL?:iid=1'"
              target="_blank">
              Recepción materia prima y embalado Chile &ensp;
              <n-icon size="12">
                <ExternalLinkAlt />
              </n-icon>
            </a>
          </div>
          <n-divider />
          <div>
            <a style="text-decoration: none; color: inherit;"
              :href="'https://tableau.hortifrut.com/#/views/BalancedeMasa_16669799478430/Arndanos-BalancedeMasa?:iid=3'"
              target="_blank">
              Balance de masas Chile &ensp;
              <n-icon size="12">
                <ExternalLinkAlt />
              </n-icon>
            </a>
          </div>
          <n-divider />
          <div>
            <a style="text-decoration: none; color: inherit;"
              :href="'https://tableau.hortifrut.com/#/views/REPORTEDEMANOOBRA-OPERACIONESHFCH/REPORTEDEMANOOBRA-OPERACIONESHFCH?:iid=2'"
              target="_blank">
              Costos mano de obra &ensp;
              <n-icon size="12">
                <ExternalLinkAlt />
              </n-icon>
            </a>
          </div>
          <n-divider />
          <div>
            <a style="text-decoration: none; color: inherit;"
              :href="'https://tableau.hortifrut.com/#/views/InformeOperaciones-Cerezas/Cerezas-InformedeProcesos'"
              target="_blank">
              Informe procesos cerezas &ensp;
              <n-icon size="12">
                <ExternalLinkAlt />
              </n-icon>
            </a>
          </div>
          <n-divider />
          <div>
            <a style="text-decoration: none; color: inherit;"
              :href="'https://tableau.hortifrut.com/#/views/PEA-F-OI-TBL-003_01ResumenPackings/BalancedeMasa?:iid=1'"
              target="_blank">
              Balance de masa Trujillo &ensp;
              <n-icon size="12">
                <ExternalLinkAlt />
              </n-icon>
            </a>
          </div>
          <n-divider />
          <div>
            <a style="text-decoration: none; color: inherit;"
              :href="'https://tableau.hortifrut.com/#/views/PEA-F-OI-TBL-003_01ResumenPackings/PorVariedad?:iid=1'"
              target="_blank">
              Rendimiento por variedad Trujillo &ensp;
              <n-icon size="12">
                <ExternalLinkAlt />
              </n-icon>
            </a>
          </div>
          <n-divider />
          <div>
            <a style="text-decoration: none; color: inherit;"
              :href="'https://tableau.hortifrut.com/#/views/PEA-F-OI-TBL-003_01ResumenCMO/RatioCMOkgPT?:iid=1'"
              target="_blank">
              Ratio CMO Trujillo &ensp;
              <n-icon size="12">
                <ExternalLinkAlt />
              </n-icon>
            </a>
          </div>
          <n-divider />
          <div>
            <a style="text-decoration: none; color: inherit;"
              :href="'https://tableau.hortifrut.com/#/views/5_CostosPackingOrigenPEA-F-OI-TBL-005_02/CostoPackingOrigen?:iid=2'"
              target="_blank">
              Costo Packing origen – SKU Trujillo &ensp;
              <n-icon size="12">
                <ExternalLinkAlt />
              </n-icon>
            </a>
          </div>
          <n-divider />
        </div>
      </n-grid-item>
    </n-grid><br><br>
    <div>
      <h5>If you do not find any process, please request access to portaloperaciones@hortifrut.com</h5>
    </div>


    <router-view></router-view>
  </div>
</template>

<script>
import { ExternalLinkAlt } from '@vicons/fa'

export default {

  name: 'menuOperations',
  components: {
    ExternalLinkAlt
  }
}

</script>

