<template>
    <div style="text-align: center;">
        <div>
          <n-carousel autoplay>
              
              <img
                class="carousel-img"
                src="https://www.hortifrut.com/wp-content/uploads/2021/12/arandanos-en.jpg"
              >
              <img
                class="carousel-img"
                src="https://www.hortifrut.com/wp-content/uploads/2021/12/frambuesa-en.jpg"
              >
              <img
                class="carousel-img"
                src="https://www.hortifrut.com/wp-content/uploads/2021/12/moras-en.jpg"
              >
            </n-carousel>
        </div>
        <br>
        <br>
  
        <router-view ></router-view>
    </div>
</template>

<script>

export default {
  name: 'MainMenu'
}
</script>

<style>
  .carousel-img {
  width: 100%;
  height: auto;
  object-fit: cover;
}
</style>