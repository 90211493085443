<template>
    <div style="text-align: center;">
        
        <n-image width="500" src="https://i.pinimg.com/564x/11/e1/b8/11e1b8c66fd6e0d43cd8a1cc06887a88.jpg"/>
    </div>
</template>

<script>
    export default ({
        name: 'UnderConstruction'
    })
</script>