<template>

  <br>
  <div>
    <n-breadcrumb>
      <n-breadcrumb-item href="#menuprocesos">Processes</n-breadcrumb-item>
      <n-breadcrumb-item href="#menuprocesos/menuIBP">SAP IBP</n-breadcrumb-item>
      <n-breadcrumb-item>Product ID Analysis</n-breadcrumb-item>
    </n-breadcrumb>
  </div>

  <h1>Product ID Analysis </h1>
  <div>
    <n-divider />
  </div>
  <div>
    <n-tabs type="segment" animated>
      <n-tab-pane name="tab-1" tab="Current Scenario">
        <n-grid :x-gap="12" :y-gap="8" :cols="1">
          <n-grid-item :span="3" style="text-align: center;">
            <h3>Current Scenario</h3>
            Scenario in File: {{ scenario_in_file }}
            <br>
            Scenario to Process: {{ scenario_to_process }}
            <br>
            <br>
            <n-popconfirm positive-text="Confirm" negative-text="Cancel" @positive-click="download_product_id">
              <template #trigger>
                <n-button type="primary"> Download the Product ID </n-button>
              </template>
              Confirm the Product ID download?
            </n-popconfirm>
            <!-- <n-button type="primary" @click="download_product_id"> Download the Product ID </n-button> -->
          </n-grid-item>
        </n-grid>
      </n-tab-pane>
      <n-tab-pane name="tab-2" tab="Update New Scenario">
        <n-grid :x-gap="12" :y-gap="8" :cols="12">
          <n-grid-item :span="4" style="text-align: center;"></n-grid-item>
          <n-grid-item :span="4" style="text-align: center;">
            <h3>Update Scenario</h3>
            Scenario Name: <n-input v-model:value="scenario_input" type="text" placeholder="Scenario" />
            <br><br>
            Period From: <n-input-number v-model:value="period_from_input" placeholder="-2" :min="-20" :max="25" />
            <br>
            Period To: <n-input-number v-model:value="period_to_input" placeholder="7" :min="-20" :max="25" />
            <br><br>
            <n-popconfirm positive-text="Confirm" negative-text="Cancel" @positive-click="update_scenario_name">
              <template #trigger>
                <n-button type="primary"> Update Scenario to Process </n-button>
              </template>
              Confirm Update Scenario to Process?
            </n-popconfirm>

            <br><br><br>
            <n-button type="primary" @click="getInfoLastExecution"> Refresh </n-button>
          </n-grid-item>
          <n-grid-item :span="4" style="text-align: center;">
          </n-grid-item>
        </n-grid>
        <br>
        <div v-if="show_alert_error_tab2 == true">
          <n-alert title="Error" type="error" closable>
            <pre>{{ message_alert }}</pre>
          </n-alert>
        </div>
        <div v-if="show_alert_success_tab2 == true">
          <n-alert title="Ok!" type="success" closable>
            <pre>{{ message_alert }}</pre>
          </n-alert>
        </div>
      </n-tab-pane>
      <n-tab-pane name="tab-3" tab="Run OData Extractor">
        <n-grid :x-gap="12" :y-gap="8" :cols="1">
          <n-grid-item :span="3" style="text-align: center;">
            <br>
            <br>
            Scenario to Process: {{ scenario_to_process }}
            <br>
            <br><n-popconfirm positive-text="Confirm" negative-text="Cancel"
              @positive-click="extract_bw_aws_odata_tcr_data">
              <template #trigger>
                <n-button type="primary"> Run ETL TranspCostRate </n-button>
              </template>
              Confirm Run ETL TranspCostRate?
            </n-popconfirm>

            <n-divider vertical />
            <n-popconfirm positive-text="Confirm" negative-text="Cancel"
              @positive-click="extract_bw_aws_odata_all_data">
              <template #trigger>
                <n-button type="primary"> Run ETL All OData </n-button>
              </template>
              Confirm Run ETL All OData?
            </n-popconfirm>

            <n-divider vertical />
            <n-popconfirm positive-text="Confirm" negative-text="Cancel"
              @positive-click="extract_bw_aws_odata_output_data">
              <template #trigger>
                <n-button type="primary"> Run ETL Output OData </n-button>
              </template>
              Confirm Run ETL Output OData?
            </n-popconfirm>

            <n-divider vertical />
            <!-- <n-button type="primary" @click="ejecutar_cruce_datos"> Run Data Process on AWS</n-button> -->
            <br>
            <br>
          </n-grid-item>
        </n-grid>
        <br>
        <div v-if="show_alert_error_tab3 == true">
          <n-alert title="Error" type="error" closable>
            <pre>{{ message_alert }}</pre>
          </n-alert>
        </div>
        <div v-if="show_alert_success_tab3 == true">
          <n-alert title="Ok!" type="success" closable>
            <pre>{{ message_alert }}</pre>
          </n-alert>
        </div>
        <br><br>
        <div style="height: 300px; border: solid 1px #ddd;">
          <c-grid :data="records">
            <c-grid-input-column field="name" width="35%" min-width="150">
              Id
            </c-grid-input-column>
            <c-grid-input-column field="startDate" width="20%" min-width="150">
              Started On
            </c-grid-input-column>
            <c-grid-input-column field="stopDate" width="20%" min-width="150">
              Completed On
            </c-grid-input-column>
            <c-grid-input-column field="status" width="25%" min-width="150">
              Status
            </c-grid-input-column>
          </c-grid>
        </div>
      </n-tab-pane>
      <n-tab-pane name="tab-4" tab="Join Multiple Scenarios">
        <n-grid :x-gap="12" :y-gap="8" :cols="1">
          <n-grid-item :span="3" style="text-align: center;">
            <br>
            <h3>Current Joined Scenarios </h3>
            {{ joined_scenarios }}
            <br>
            <br>
            <n-popconfirm positive-text="Confirm" negative-text="Cancel" @positive-click="join_current_output">
              <template #trigger>
                <n-button type="primary"> Join Current Output </n-button>
              </template>
              Confirm Join Current Output?
            </n-popconfirm>

            <n-divider vertical />
            <n-popconfirm positive-text="Confirm" negative-text="Cancel" @positive-click="clean_joined_output">
              <template #trigger>
                <n-button type="primary"> Clean Joined Output </n-button>
              </template>
              Confirm Clean Joined Output?
            </n-popconfirm>

            <!-- <n-divider vertical />
            <n-button type="primary" @click="download_joined_output"> Download Joined Output </n-button>
             -->
            <br>
            <br>
            <div v-if="show_alert_error_tab4 == true">
              <n-alert title="Error" type="error" closable>
                <pre>{{ message_alert }}</pre>
              </n-alert>
            </div>
            <div v-if="show_alert_success_tab4 == true">
              <n-alert title="Ok!" type="success" closable>
                <pre>{{ message_alert }}</pre>
              </n-alert>
            </div>
          </n-grid-item>
        </n-grid>
      </n-tab-pane>
    </n-tabs>
  </div>
  <br>


</template>

<script>
import axios from 'axios';
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "UniversoFactible",
  mounted() {
    // this.obtenerEstadoProcesosGlue();
    this.getInfoLastExecution();
  },
  data() {
    return {
      options: [
        { label: "Feasible Universe", value: "1" },
        { label: "Product ID Analysis", value: "2" }
      ],
      placeholder_select: "Select a Process",
      records: [],
      placeholder_input: "60",
      presignedurl_excel: ref(null),
      periodos: ref(null),
      proceso: ref(null),
      show_alert_success_tab2: ref(null),
      show_alert_success_tab3: ref(null),
      show_alert_success_tab4: ref(null),
      show_alert_error_tab2: ref(null),
      show_alert_error_tab3: ref(null),
      show_alert_error_tab4: ref(null),
      message_alert: ref(null),
      scenario_in_file: ref(null),
      scenario_to_process: ref(null),
      scenario_input: ref(null),
      period_from_input: ref(null),
      period_to_input: ref(null),
      presignedurl_excel_joined: ref(null),
      joined_scenarios: ref(null),
    };
  },
  methods: {
    getInfoLastExecution() {
      axios.get("https://14u51m5kzc.execute-api.us-west-2.amazonaws.com/hf-prod-stage/universo-factible/odata-model/obtener-detalle-ejecucion")
        .then((response) => {
          console.log(response.data);
          this.presignedurl_excel = response.data.item.Item.presignedurl.S;
          this.scenario_in_file = response.data.item.Item.scenario.S;

          this.scenario_to_process = response.data.item2.Item.scenario.S;
          this.scenario_input = response.data.item2.Item.scenario.S;
          this.period_from_input = response.data.item2.Item.period_from.N;
          this.period_to_input = response.data.item2.Item.period_to.N;
          this.records = response.data.sfn.executions;
          this.joined_scenarios = response.data.item3;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    download_product_id() {
      window.open(this.presignedurl_excel, '_blank', 'noreferrer');
    },
    update_scenario_name() {
      axios.post("https://14u51m5kzc.execute-api.us-west-2.amazonaws.com/hf-prod-stage/universo-factible/odata-model/actualizar-escenario",
        { 'scenario': this.scenario_input, 'period_from': this.period_from_input, 'period_to': this.period_to_input }).
        then((response) => {
          console.log(response);
          this.show_alert_success_tab2 = true
          this.message_alert = "RequestId: " + response.data.ResponseMetadata.RequestId
        }).catch((error) => {
          console.log(error);
          this.show_alert_error_tab2 = true
          this.message_alert = "Error Updating Data"
        });
      // this.response_status = 200;
    },
    extract_bw_aws_odata_all_data() {
      axios.put("https://14u51m5kzc.execute-api.us-west-2.amazonaws.com/hf-prod-stage/universo-factible/odata-model/run-odata-extractor",
        { 'process': 'all' }).
        then((response) => {
          console.log(response);
          this.show_alert_success_tab3 = true
          this.message_alert = "RequestId: " + response.data.ResponseMetadata.RequestId
        }).catch((error) => {
          console.log(error);
          this.show_alert_error_tab3 = true
          this.message_alert = "Error running OData Process"
        }).finally(
          this.getInfoLastExecution()
        );
    },
    extract_bw_aws_odata_output_data() {
      axios.put("https://14u51m5kzc.execute-api.us-west-2.amazonaws.com/hf-prod-stage/universo-factible/odata-model/run-odata-extractor",
        { 'process': 'output' }).
        then((response) => {
          console.log(response);
          this.show_alert_success_tab3 = true
          this.message_alert = "RequestId: " + response.data.ResponseMetadata.RequestId
        }).catch((error) => {
          console.log(error);
          this.show_alert_error_tab3 = true
          this.message_alert = "Error running OData Process"
        }).finally(
          this.getInfoLastExecution()
        );
    },
    extract_bw_aws_odata_tcr_data() {
      axios.put("https://14u51m5kzc.execute-api.us-west-2.amazonaws.com/hf-prod-stage/universo-factible/odata-model/run-odata-extractor",
        { 'process': "transpcostrate" }).
        then((response) => {
          console.log(response);
          this.show_alert_success_tab3 = true
          this.message_alert = "RequestId: " + response.data.ResponseMetadata.RequestId
        }).catch((error) => {
          console.log(error);
          this.show_alert_error_tab3 = true
          this.message_alert = "Error running OData Process"
        }).finally(
          this.getInfoLastExecution()
        );
    },
    join_current_output() {
      axios.patch("https://14u51m5kzc.execute-api.us-west-2.amazonaws.com/hf-prod-stage/universo-factible/odata-model/join-new-product-id-output").
        then((response) => {
          console.log(response);
          this.show_alert_success_tab4 = true
          this.message_alert = "Table Joined"
        }).catch((error) => {
          console.log(error);
          this.show_alert_error_tab4 = true
          this.message_alert = "Error Joining table"
        }).finally(
          this.getInfoLastExecution()
        );
    },
    clean_joined_output() {
      axios.delete("https://14u51m5kzc.execute-api.us-west-2.amazonaws.com/hf-prod-stage/universo-factible/odata-model/clean-joined-output").
        then((response) => {
          console.log(response);
          this.show_alert_success_tab3 = true
          this.message_alert = "RequestId: " + response.data.ResponseMetadata.RequestId
        }).catch((error) => {
          console.log(error);
          this.show_alert_error_tab3 = true
          this.message_alert = "Error running OData Process"
        }).finally(
          this.getInfoLastExecution()
        );
    },
    download_joined_output() {
      window.open(this.presignedurl_excel_joined, '_blank', 'noreferrer');
    },
  },
  components: {}
});
</script>

