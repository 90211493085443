import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import CGrid from "vue-cheetah-grid";

import {
    // create naive ui
    create,
    // component
    NMenu, NIcon, NCarousel, NCard,
    NButton, NDivider, NSelect, NInputNumber,
    NBreadcrumb, NBreadcrumbItem, NImage,
    NGrid, NGridItem, NGi, NAlert, NTabs, NTabPane,
    NUpload, NInput, NPopconfirm
  } from 'naive-ui'

const naive = create({
components: [NMenu, NIcon,  NCarousel, NCard,
            NButton, NDivider, NSelect, NInputNumber,
            NBreadcrumb, NBreadcrumbItem, NImage,
            NGrid, NGridItem, NGi, NAlert, NTabs, NTabPane,
            NUpload, NInput, NPopconfirm]
},
)


createApp(App).use(router).use(CGrid).use(naive).mount('#app')
