<template>
  <div style="text-align: center;">
    <h1>Support</h1>
    <br>
    <!-- <UnderConstruction /> -->


    <div>
      <h5>If you do not find any process, please request access to portaloperaciones@hortifrut.com</h5>
    </div>


    <router-view></router-view>
  </div>
</template>

<script>

// import UnderConstruction from '@/components/UnderConstruction.vue';

export default {
  name: 'MenuSoporte',
  components: {
    // UnderConstruction
  }
}

</script>

