import { PublicClientApplication } from "@azure/msal-browser";

var msalConfig = {
    auth: {
        clientId: "5da5d3c1-8c4f-4001-aef7-37999154752b",
        authority: "https://login.microsoftonline.com/e5dc79d7-7660-4dd3-a6d6-af6e69502e10/",

        redirectURI: "http://localhost:8080/"
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false
    },
};

var requestObj = {
    scopes: ["User.Read"]
};

var myMSALObj = new PublicClientApplication(msalConfig);
let username = "";

myMSALObj.handleRedirectPromise()
    .then(handleResponse)
    .catch((error) => {
        console.error(error);
    });

function handleResponse(response) {
    if (response !== null) {

        username = response.account.username;
        console.log(response.account.username)
        myMSALObj.setActiveAccount(response.account)
        return username

        // showWelcomeMessage(username);
    } else {
        selectAccount();
    }
}

function selectAccount() {

    /**
     * See here for more info on account retrieval: 
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
     */

    const currentAccounts = myMSALObj.getAllAccounts();

    if (currentAccounts.length === 0) {
        return;
    } else if (currentAccounts.length > 1) {
        // Add your account choosing logic here
        // console.warn("Multiple accounts detected.");
    } else if (currentAccounts.length === 1) {
        username = currentAccounts[0].username;
        // showWelcomeMessage(username);
    }
}

var login = async () => {
    var authResult = await myMSALObj.loginRedirect(requestObj);
    // console.log(authResult)
    return authResult.account;
};

var getAccount = async () => {
    var account = await myMSALObj.getActiveAccount();
    // console.log('getActiveAccount-aad', account)
    return account;
};

var acquireTokenSilent = function () {
    myMSALObj.acquireTokenSilent("")
        .then((authresult) => {
            // console.log('acquireTokenSilent-aad', authresult)
            return authresult;
        })
        .catch((error) => {
            console.log('error:', error)
            return null;
        })


}

var logoff = () => {
    myMSALObj.logoutRedirect();
};

export default {
    login,
    getAccount,
    logoff,
    acquireTokenSilent
};