<template>
  <div style="text-align: center;">
    <h1>Processes</h1>
    <br>


    <n-grid :x-gap="12" :y-gap="8" :cols="5">
      <n-grid-item></n-grid-item>
      <n-grid-item>
        <div>
          <n-icon size="30"><Area /></n-icon>
          <h3>SAP IBP</h3>
        </div>
        <div>
          Total Universe<br>
          Feasible Universe & Product ID Analysis<br>
          Containering Process <br>
        </div>
        <br>
        <div>
          <router-link to="/menuprocesos/menuIBP" style="text-decoration: none; color: inherit;">
            <n-button type="info"> IBP </n-button>
          </router-link>
        </div>
      </n-grid-item>
      <n-grid-item>
        <div>
          <n-icon size="30">
            <OperationsField />
          </n-icon>
          <h3>Operations</h3>
        </div>
        <div>
          Consolidated Liquidations <br>
          Valued Corporate PL <br>
          <br> <br>
        </div>
        <div>
          <!-- <router-link to="/menuprocesos/menuoperaciones" style="text-decoration: none; color: inherit;"> -->
          <n-button type="info" disabled> Operations </n-button>
          <!-- </router-link> -->
        </div>
      </n-grid-item>
      <n-grid-item>
        <div>
          <n-icon size="30">
            <Data1 />
          </n-icon>
          <h3>Data Analytics</h3>
        </div>
        <div>
          Thor<br>
          Rubik<br>
          <br><br>
        </div>
        <div>
          <!-- <router-link to="/menuprocesos/menudataanalytics" style="text-decoration: none; color: inherit;"> -->
          <n-button type="info" disabled> Data Analytics </n-button>
          <!-- </router-link> -->
        </div>
      </n-grid-item>
      <n-grid-item></n-grid-item>
    </n-grid><br><br>
    <div>
      <h5>If you do not find any process, please request access to portaloperaciones@hortifrut.com</h5>
    </div>


    <router-view></router-view>
  </div>
</template>

<script>
import { Data1, OperationsField, Area } from '@vicons/carbon'

export default {
  name: 'MenuProcesos',
  components: {
    Data1, OperationsField, Area
  }
}

</script>

