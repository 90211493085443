<template>
  <br>
  <div>
    <n-breadcrumb>
      <n-breadcrumb-item>Monitors</n-breadcrumb-item>
      <n-breadcrumb-item>S&OP</n-breadcrumb-item>
    </n-breadcrumb>
  </div>

  <div style="text-align: center;">

    <n-grid :x-gap="12" :y-gap="8" :cols="3">
      <n-grid-item>
        <div>
          <h1>Monitors <br>Management Control</h1>
          <!-- <h4>Estado y Ejecuciones</h4> -->
          <br>
        </div>
      </n-grid-item>
      <n-grid-item :span="2">

        <div>
          <n-divider />
          <div>
            <a style="text-decoration: none; color: inherit;"
              :href="'https://tableau.hortifrut.com/views/SeguimientoCAPEX/Dashboard2?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link'"
              target="_blank">
              CAPEX Monitor - Operations &ensp;
              <n-icon size="12">
                <ExternalLinkAlt />
              </n-icon>
            </a>
          </div>
          <n-divider />
          <div>
            <a style="text-decoration: none; color: inherit;">
              Monitor - Management Control &ensp;
              <!-- <n-icon size="12">
                <ExternalLinkAlt />
              </n-icon> -->
            </a>
          </div>
          <n-divider />
          <div>
            <a style="text-decoration: none; color: inherit;">
              Budget Analysis &ensp;
              <!-- <n-icon size="12">
                <ExternalLinkAlt />
              </n-icon> -->
            </a>
          </div>
          <n-divider />
        </div>
      </n-grid-item>
    </n-grid><br><br>
    <div>
      <h5>If you do not find any process, please request access to portaloperaciones@hortifrut.com</h5>
    </div>


    <router-view></router-view>
  </div>
</template>

<script>
import { ExternalLinkAlt } from '@vicons/fa'

export default {
  name: 'menuIBP',
  components: {
    ExternalLinkAlt
  }
}

</script>

