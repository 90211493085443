<template>
  <br>
  <div>
    <n-breadcrumb>
      <n-breadcrumb-item>Monitors</n-breadcrumb-item>
      <n-breadcrumb-item>SAP IBP</n-breadcrumb-item>
    </n-breadcrumb>
  </div>

  <div style="text-align: center;">

    <n-grid :x-gap="12" :y-gap="8" :cols="3">
      <n-grid-item>
        <div>
          <h1>Monitors <br>SAP IBP</h1>
          <!-- <h4>Estado y Ejecuciones</h4> -->
          <br>
        </div>
      </n-grid-item>
      <n-grid-item :span="2">

        <div>
          <n-divider />

          <div>
            <a style="text-decoration: none; color: inherit;" :href="'https://tableau.hortifrut.com/views/UniversoTotal/UNIVERSOTOTAL?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link'" target="_blank">
              Total Universe &ensp; <n-icon size="12">
                <ExternalLinkAlt />
              </n-icon>
            </a>
          </div>
          <n-divider />
          <div>
            <a style="text-decoration: none; color: inherit;" :href="'https://tableau.hortifrut.com/views/ProductIDUniversoFactible-PresupuestoT2223/INTEGRATEDBUSINESSPLANNING/8ce24b69-5694-473a-a80b-909273f85e36/df31a46a-3ba7-417f-9a18-e9d1fd81bf76?:display_count=n&:showVizHome=n&:origin=viz_share_link'" target="_blank">
              Feasible Universe &ensp; 
              <n-icon size="12"><ExternalLinkAlt /></n-icon>
            </a>
          </div>
          <n-divider />
          <div>
            <a style="text-decoration: none; color: inherit;" :href="'https://tableau.hortifrut.com/views/AnalisisdeProductIDvLT/AnlisisProductID_/f5b9d40e-7884-415c-ad56-4164317c8e3d/89055424-3212-4495-8eae-10a501459a3a?:display_count=n&:showVizHome=n&:origin=viz_share_link'" target="_blank">
              Product ID Analysis &ensp; 
              <n-icon size="12"><ExternalLinkAlt /></n-icon>
            </a>
          </div>
          <n-divider />
          <div>
            <a style="text-decoration: none; color: inherit;">
              Logistics IBP &ensp; 
              <!-- <n-icon size="12"><ExternalLinkAlt /></n-icon> -->
            </a>
          </div>
          <n-divider />
          <div>
            <a style="text-decoration: none; color: inherit;">
              Containering Processs &ensp; 
              <!-- <n-icon size="12"><ExternalLinkAlt /></n-icon> -->
            </a>
          </div>
          <n-divider />
        </div>
      </n-grid-item>
    </n-grid><br><br>
    <div>
      <h5>If you do not find any process, please request access to portaloperaciones@hortifrut.com</h5>
    </div>


    <router-view></router-view>
  </div>
</template>

<script>
import { ExternalLinkAlt } from '@vicons/fa'

export default {

  name: 'menuIBP',
  components: {
    ExternalLinkAlt
  }
}

</script>

